<template>
  <div v-if="currentGames.length" :class="['group-recently', { 'group-recently_is-mobile': isMobile }]">
    <atomic-cat-heading
      class="group-recently__title"
      :icon="
        getContent(globalComponentsContent, defaultLocaleGlobalComponentsContent, 'cardsGroup.recentlyPlayed.icon')
      "
    >
      {{ getContent(globalComponentsContent, defaultLocaleGlobalComponentsContent, 'cardsGroup.recentlyPlayed.label') }}
    </atomic-cat-heading>

    <button-base class="btn-show-all" url="/recently-played">
      {{ getContent(globalComponentsContent, defaultLocaleGlobalComponentsContent, 'cardsGroup.moreButton') }}
    </button-base>

    <list-grid v-if="isMobile" class="group-games-adaptive__mobile-list" :items="currentGames" />

    <template v-else>
      <button-arrows
        v-if="showArrowButtons"
        :prevDisabled="prevDisabled"
        :nextDisabled="nextDisabled"
        @click-action="clickAction"
      />

      <div
        ref="scrollContainer"
        class="items"
        :class="{ 'disabled-scroll-block': !recentlyGames.length }"
        @scroll="scrollHandler"
      >
        <card-base v-for="(game, gameIndex) in currentGames" :key="gameIndex" :gameInfo="game" />
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
  import type { IGame } from '@skeleton/core/types';

  const props = defineProps({
    showArrows: {
      type: Boolean,
      default: true,
    },
  });

  const globalStore = useGlobalStore();
  const scrollContainer = ref();
  const prevDisabled = ref(true);
  const nextDisabled = ref(false);
  const showArrowButtons = ref(true);

  const { headerCountry } = storeToRefs(globalStore);
  const deviceStore = useDeviceStore();
  const { isMobile } = storeToRefs(deviceStore);

  const recentlyGames = ref<IGame[]>([]);
  const { getRecentlyPlayed } = useCoreGamesApi();
  const profileStore = useProfileStore();
  const { profile } = storeToRefs(profileStore);
  const loadingRecently = ref(true);

  onMounted(async () => {
    try {
      recentlyGames.value = await getRecentlyPlayed({
        perPage: 18,
        platform: isMobile.value ? 1 : 2,
        countryCode: profile.value?.country || headerCountry.value || 'UA',
      });

      if (props.showArrows) {
        scrollHandler();
      }
    } finally {
      loadingRecently.value = false;
      await nextTick();
    }
  });

  const OFFSET_MARGIN = 20;

  const scrollHandler = (): void => {
    if (!scrollContainer.value) return;
    const { scrollLeft, offsetWidth, scrollWidth } = scrollContainer.value;
    prevDisabled.value = scrollLeft === 0;
    nextDisabled.value =
      scrollWidth < scrollLeft + offsetWidth + OFFSET_MARGIN && scrollWidth > scrollLeft + offsetWidth - OFFSET_MARGIN;
  };

  const clickAction = (direction: string): void => {
    const { offsetWidth, scrollWidth, scrollLeft } = scrollContainer.value;
    const widthToEnd = scrollWidth - (scrollLeft + offsetWidth);
    const scrollLeftValue = widthToEnd < offsetWidth ? widthToEnd : offsetWidth;
    const scrollRightValue = scrollLeft < offsetWidth ? scrollLeft : offsetWidth;
    scrollContainer.value.scrollBy({
      left: direction === 'next' ? scrollLeftValue : -scrollRightValue,
      behavior: 'smooth',
    });
  };

  watch(
    () => recentlyGames.value,
    () => scrollHandler()
  );

  const { globalComponentsContent, defaultLocaleGlobalComponentsContent } = storeToRefs(globalStore);
  const { getContent } = useProjectMethods();

  const cardInBlock = ref<number>(20);
  const currentGames = computed(() => recentlyGames.value.slice(0, cardInBlock.value));
</script>

<style src="~/assets/styles/components/group/recently.scss" lang="scss" />
